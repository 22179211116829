.OculusBrowser .zoompage_planetWorldofArt2 {
     opacity: 0.5; 
}


/* #AboveCanvas.zoompage_planetInventions3, #AboveCanvas.zoompage_planetWorldofArt2 , #AboveCanvas.zoompage_planetEPO2  {      
    background-color: red;
}  */

@media (max-width: 1400px) { 
  
     #AboveCanvas.zoompage_planetInventions3, #AboveCanvas.zoompage_planetWorldofArt2 , #AboveCanvas.zoompage_planetEPO2  {      
       /*    background-color: green; */
          display: none;
     }
}


/* canvas { */
     /* width: 100vw !important; */
/* } */
