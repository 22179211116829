.ArtworkSpan p, .ArtworkSpan span {
        font-size: 14pt;
        font-family: "Source Sans Pro", Helvetica, Arial, Verdana, Tahoma, sans-serif;
}

p {
    margin-bottom: 2ex;
}


@page {}

table {
    border-collapse: collapse;
    border-spacing: 0;
    empty-cells: show
}

td,
th {
    vertical-align: top;
    font-size: 12pt;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    clear: both;
}

ol,
ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
    margin: 0;
    padding: 0;
}

/* "li span.odfLiEnd" - IE 7 issue*/
li span {
    clear: both;
    line-height: 0;
    width: 0;
    height: 0;
    margin: 0;
    padding: 0;
}

span.footnodeNumber {
    padding-right: 1em;
}

span.annotation_style_by_filter {
    font-size: 95%;

    background-color: #fff000;
    margin: 0;
    border: 0;
    padding: 0;
}

span.heading_numbering {
    margin-right: 0.8rem;
}

* {
    margin: 0;
}
