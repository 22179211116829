.content {
  background-color: #263238;
}


.modal-win-outer {
  background-color: #263238;
  color: white;
  margin: 5;
  position: absolute; 
  box-shadow: 1px;
  border-radius: 5px; 
  display: flex;
  flex-direction: "columns"; 
 
  height: 100vh;
  z-index: 9999;

  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%;

  transform: translate(-50%, -50%);
}




.modal-win-button {
    display: absolute;
    top:5;
    right:5;
    margin-left: 5px; 
    font-size: 18px;
    font-weight: bold;
    align-self: flex-end;

    color: white;
    background-color: #263238;
    width:16;
    height: 16;
    border: none;
} 




.content2 {
  background-color: #263238;
  border-radius: 10px;
  width: 82vw;
}


#modal-root2 .modal-win-outer2 {
  background-color: #263238;
  color: white;
  margin: 5px;
  position: absolute; 
  display: flex;
  /* left: 10vw; */
  z-index: 9999;
 /*  top:  50px; */
  /* width: 82vw; */
  border-radius: 10px;
  /* height: 80vh; */

  top: 50%;  /* position the top  edge of the element at the middle of the parent */
  left: 50%;

  transform: translate(-50%, -50%);
}


#modal-root2 .modal-win-outer2-backdrop  {
  content: "";
  position: absolute;
  display: block;
  height: 100vh;
  width: 100vw;
  left: 0;
  top:0;
  z-index: 200;
  background-color: rgba(0.5, 0.5, 0.5, 0.4);
}  



.modal-win-button2 {
    display: flex;
    top:5;
    right:5;
    margin-left: 5px; 
    font-size: 18px;
    font-weight: bold;
    align-self: flex-end;

    color: white;
    background-color: #263238;
    width:16;
    height: 16;
    border: none;
} 