#TopMenu #trigg {
    right: 60px;
}



@media (max-width: 800px) {  
    #TopMenu #trigg {
        right: 15px;
    }
}