.desktop .smallLogo3D {
    width: calc(100vw - 18px);  
}

.smallLogo3D {
    width: 100vw;  
    background: rgba(35, 33, 44, 1);
    background: linear-gradient(180deg, rgba(35, 33, 44, 1) 61%, rgba(0,0,0,0) 110%);
}


.desktop.zoompage_galleryentrance .smallLogo3D {
    width: 100vw; 
}